import Loading from "vue-loading-overlay";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";
import axios from "axios";
import PictureInput from "vue-picture-input";

import * as config from "@/config.json";

export default {
  name: "DeviceHistory",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    PictureInput
  },
  data: () => {
    return {
      isLoading: false,
      token: null,
      apps: [],
      fieldsApps: [{
          key: "logoUrl",
          label: "Logo",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "masterDivisionType",
          label: "Tipo Divisão",
        },
        {
          key: "isCustomerDocumentCheck",
          label: "Verificação de Documento",
        },
        {
          key: "isProfilePhotoRequired",
          label: "Foto de Perfil",
        },
        {
          key: "discountType",
          label: "Tipo Desconto",
        },
        {
          key: "discountValue",
          label: "Valor Desconto",
        },
        {
          key: "weighingMachineId",
          label: "Balança",
        },
        {
          key: "updatedAt",
          label: "Atualização",
        },
        {
          key: "action",
          label: "",
        }
      ],

      selectedOptionMasterDivision: null,
      optionsMasterDivision: [{
          value: 0,
          text: "Selecione um tipo de divisão",
        },
        {
          value: 1,
          text: "organization",
        },
        {
          value: 2,
          text: "company",
        },
        {
          value: 3,
          text: "site",
        }
      ],
      appId: "",
      appName: "",
      appDescription: "",
      appMasterDivisionId: "",
      appMasterDivisionType: "",
      appImage: null,
      isCustomerDocumentCheck: false,
      isProfilePhotoRequired: false,
      discountValue: 0,
      selectedOptionDiscountType: null,
      optionsDiscountType: [{
          value: 0,
          text: "Selecione um tipo de desconto",
        },
        {
          value: 1,
          text: "Valor",
        },
        {
          value: 2,
          text: "Porcentagem",
        }
      ],

      isAllowedEditApp: false,
      selectedWeighingMachine: null,
      optionWeighingMachine: [],
      isCollapseOpen: false,
      appDailyToken: "",
      showPasswordSitef: false,
      typeInput: "password",
      firebaseKey: "",
      url: null,
    };
  },
  methods: {

    getColumnClass() {
      if (window.innerWidth <= 400) {
        return 'col-xs-12';
      } else {
        return 'col-md-7';
      }
    },

    onRemoved() {
      this.appImage = '';
      this.url = ''
    },

    onChange(image) {
      if (image) {
        this.appImage = image;
      }
    },

    lockAppEdition() {
      if (this.isAllowedEditApp) {
        this.isAllowedEditApp = false
      }
    },

    async allowedEditionApp() {
      try {
        if (this.appDailyToken != '') {
          const response = await axios({
            url: `${config.default.urlBase}/support/daily/token`,
            method: "GET",
            headers: {
              authorization: `Bearer ${this.token}`
            }
          })

          if (response.status == 200) {
            this.DailyToken = response.data.token

            if (this.DailyToken === this.appDailyToken) {
              this.isAllowedEditApp = true
              this.focus("inputAppName")
              this.appDailyToken = ''
              this.isCollapseOpen = false

            } else if (this.appDailyToken != '') {
              this.isAllowedEditApp = false

              await this.$alert(
                "Token Incorreto!",
                "Aviso",
                "warning"
              );
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isAllowedEditApp = false
      }
    },

    showPasswordSitefConfiguration() {
      this.showPasswordSitef = !this.showPasswordSitef

      if (this.showPasswordSitef) {
        this.typeInput = "text"
      } else {
        this.typeInput = "password"
      }

      this.focus("appDailyToken")
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async getWeighingMachine() {
      const url = `${config.default.urlBase}/weighing-machine`;

      try {
        const response = await axios({
          url,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          response.data;

          this.optionWeighingMachine = await Promise.all(response.data.map((item, index) => {
            return {
              value: index,
              id: item.id,
              text: `ID: ${item.id} - Tipo: ${item.labelType} - Tamanho Código: ${item.codeSize} - Prefixo: ${item.prefix}`,
            };
          }));
        }

      } catch (error) {
        console.log({
          error
        })
      }
    },

    cancelAppConfiguration() {
      this.$refs.editApplication.hide();
    },

    buildAppData(appId, name, description, weighingMachineId, discountType, discountValue, logo, firebaseKey, isCustomerDocumentCheck, isProfilePhotoRequired) {
      let discountValueFormated = parseFloat(discountValue);

      const appData = {
        appId,
        name,
        description,
        weighingMachineId,
        discountType,
        discountValue: discountValueFormated,
        firebaseKey,
        isCustomerDocumentCheck,
        isProfilePhotoRequired
      }

      if (logo != null && logo != undefined && logo != "") {
        appData.logo = logo
      }

      return appData
    },

    async verifyDigits() {
      if (this.discountValue > 9999) {
        await this.$alert(
          "Valor não permitido! Valor Máximo 9999",
          "Aviso",
          "warning"
        )
      }
    },


    async confirmAppConfiguration() {
      this.isLoading = true

      if (this.selectedOptionDiscountType != 1 && this.selectedOptionDiscountType != 2) {
        this.isLoading = false

        await this.$alert(
          "Tipo de desconto inválido!",
          "Aviso",
          "warning"
        );
        return
      }

      const appData = this.buildAppData(
        this.appId,
        this.appName,
        this.appDescription,
        this.selectedWeighingMachine != null ? this.optionWeighingMachine[this.selectedWeighingMachine].id : 0,
        this.selectedOptionDiscountType,
        this.discountValue,
        this.appImage,
        this.firebaseKey,
        this.isCustomerDocumentCheck,
        this.isProfilePhotoRequired
      )

      try {
        const url = `${config.default.urlBase}/apps`;

        const response = await axios({
          url,
          method: "PATCH",
          data: appData,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });


        if (response.status == 200) {
          this.isLoading = false

          this.$refs.editApplication.hide();

          await this.$alert(
            "Aplicativo atualizado!",
            "Sucesso",
            "success"
          )
        }

        await this.getApps()

      } catch (error) {
        console.error(error)

        this.isLoading = false

        this.$refs.editApplication.hide();


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro atualizar o aplicativo! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    convertBase64ToImage(url) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            this.appImage = reader.result;
          };
        } else {
          console.error('Error fetching image:', xhr.status);
        }
      };
      xhr.send();
    },

    async showEditApplication(app) {
      this.isCollapseOpen = false
      this.appDailyToken = ''
      this.isAllowedEditApp = false

      this.appId = app.id;
      this.appName = app.name;
      this.appDescription = app.description;
      this.appMasterDivisionId = app.masterDivisionId;
      this.appMasterDivisionType = app.masterDivisionType;
      this.url = app.logoUrl

      if (this.url != null && this.url != "") {
        this.appImage = await this.convertBase64ToImage(this.url)
      } else {
        this.appImage = null
      }

      let selectedOptionMasterDivision
      selectedOptionMasterDivision = this.optionsMasterDivision.find(option => option.text === app.masterDivisionType)
      this.selectedOptionMasterDivision = selectedOptionMasterDivision ? selectedOptionMasterDivision.value : 0

      let selectedOptionDiscountType
      selectedOptionDiscountType = this.optionsDiscountType.find(option => option.value === app.discountType)

      this.selectedOptionDiscountType = selectedOptionDiscountType ? selectedOptionDiscountType.value : 0

      this.discountValue = app.discountValue

      this.isCustomerDocumentCheck = app.isCustomerDocumentCheck
      this.isProfilePhotoRequired = app.isProfilePhotoRequired


      await this.getWeighingMachine();

      let returnSelectedWeighingMachine
      returnSelectedWeighingMachine = this.optionWeighingMachine.find(option => option.id === app.weighingMachineId)
      this.selectedWeighingMachine = returnSelectedWeighingMachine ? returnSelectedWeighingMachine.value : null


      this.$refs.editApplication.show();
    },

    async getApps() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.apps = response.data;
        }
      } catch (error) {
        console.log({
          error,
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro atualizar o aplicativo! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    clearWeighingMachine() {
      this.selectedWeighingMachine = null
    },


    redirect(ref) {
      this.$router.push(ref);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    this.getApps();
    this.isLoading = false;
  },
};