import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../../components/PaginationNav.vue";

import * as config from "@/config.json";

export default {
  name: "DiscountCouponDetailsList",
  components: {
    Loading,
    PaginationNav,
  },

  props: ["source"],

  data: () => {
    return {
      idCoupon: null,
      pagination: {},
      paginationRange: 11,
      token: "",
      isLoading: false,
      perPage: 8,
      currentPage: 1,
      detailsCoupon: [],
      fieldsDetailsCoupon: [{
          key: "id",
          label: "ID Cupom"
        }, {
          key: "customerId",
          label: "ID Cliente"
        },
        {
          key: "name",
          label: "Cliente"
        },
        {
          key: "document",
          label: "CPF Cliente"
        },
        {
          key: "isUsed",
          label: "Utilizado"
        },
        {
          key: "useDate",
          label: "Data Utilização"
        },
        {
          key: "action",
          label: ""
        }
      ],
      descriptionCoupon: "",
      titleCoupon: "",
      activeCoupons: null,
      expiredCoupons: null,
      usedCoupons: null,
      reservedCoupons: null,
      firstRegistration: null,
      reusableCoupons: null,
      totalRecords: 0
    };
  },
  methods: {
    async redirect(ref) {
      this.$router.push(ref);
    },

    // goBack() {
    //   this.$router.go(-1);
    // },

    async deleteSingleCouponDiscount(data) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/coupons/single/${data.id}`;

      try {
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            `Cupom ID: ${data.id} - Cliente: ${data.name} excluído!`,
            "Sucesso",
            "success"
          );

          await this.detailsCouponDiscount(this.idCoupon, this.currentPage);
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async detailsCouponDiscount(id, page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/coupons/${id}/details`;
      const data = await this.buildDetails(page, this.perPage)

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        console.log({
          response
        })

        if (response.status == 200) {
          this.isLoading = false;

          this.detailsCoupon = response.data.items

          this.activeCoupons = response.data.activeCoupons
          this.expiredCoupons = response.data.expiredCoupons
          this.usedCoupons = response.data.usedCoupons
          this.reservedCoupons = response.data.reservedCoupons
          this.firstRegistration = response.data.firstRegistrationCoupons
          this.reusableCoupons = response.data.reusableCoupons

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.detailsCoupon = [];
        this.pagination = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    navigate(page) {
      this.detailsCouponDiscount(this.idCoupon, page);
    },

    async buildDetails(page, perPage) {
      return {
        page,
        pageSize: perPage
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.idCoupon = this.$route.params.id;
    this.titleCoupon = this.$route.params.title;
    this.descriptionCoupon = this.$route.params.description;
    await this.detailsCouponDiscount(this.idCoupon, this.currentPage);
    this.isLoading = false;
  },
};