import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";

export default {
  name: "OrderDetails",
  components: {
    Loading,
    PaginationNav,
  },
  data: () => {
    return {
      token: null,
      perPageDetails: 8,
      itemsDetails: [],
      fieldsDetails: [{
          key: "sku",
          label: "Barras",
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "quantity",
          label: "Qtde",
        },
        {
          key: "unitValue",
          label: "Valor Unitário",
        },
        {
          key: "subTotal",
          label: "SubTotal",
        },
        {
          key: "discount",
          label: "Promoção",
        },
        {
          key: "couponsDiscountAmount",
          label: "Cupom",
        },
        {
          key: "netValue",
          label: "Valor Total",
        },
      ],
      perPagePayments: 8,
      itemsPayments: [],
      fieldsPayments: [{
          key: "date",
          label: "Data",
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "detailDescription",
          label: "Tipo",
        },
        {
          key: "amount",
          label: "Valor",
        },
        {
          key: "detailBrand",
          label: "Bandeira",
        },
        {
          key: "detailCardBin",
          label: "BIN",
        },
        {
          key: "nsuSitef",
          label: "NSU",
        }
      ],
      itemsStatus: [],
      fieldsStatus: [{
          key: "status",
          label: "Status",
        },
        {
          key: "urlPdfFile",
          label: "PDF",
        },
        {
          key: "urlXmlFile",
          label: "XML",
        }
      ],
      errorDescription: null,
      isLoading: false,
      orderId: null,
    };
  },
  methods: {
    redirect(ref) {
      if (ref == "Home") {
        this.$router.push(`/home`);
      }

      if (ref == "orders-list") {
        this.$router.push(`/orders-list`);
      }
    },

    async showOrderErrorDetails(status) {
      this.errorDescription = status.item.message;
      await this.$bvModal.show('orderErrorDetail');
    },

    async openPdf(url, error) {
      if ((url != null) && (url != "") && (url != 'undefined')) {
        window.open(url, "_blank");
      } else if (!error) {
        await this.$alert(
          "Dado não disponível!",
          "Aviso",
          "warning"
        );
      } else {
        await this.$alert(
          "Dado não disponível devido um erro ocorrido na venda. Clique no icone do Status para mais detalhes!",
          "Aviso",
          "warning"
        );
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getOrderDetails(orderId) {
      this.isLoading = true;
      const url = `${config.default.urlBase}/sites/orders/${orderId}/details`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.itemsDetails = response.data.items;
          this.itemsPayments = response.data.payments;
          this.itemsStatus = response.data.statuses
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.itemsDetails = [];
        this.itemsPayments = [];
        this.itemsStatus = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as vendas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }

        this.$router.push("/orders-list");
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;

    this.token = localStorage.getItem("token");
    this.orderId = localStorage.getItem("orderId");

    await this.getOrderDetails(this.orderId);

    this.isLoading = false;
  },

  async unmounted() {
    localStorage.removeItem("orderId");
  }
};