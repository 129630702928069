import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue";
import moment from "moment";
import * as functions from '../../../functions/functions'

export default {
  name: "OrderItems",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },
  data: () => {
    return {
      pagination: {},
      paginationRange: 6,
      currentPage: 1,
      perPage: 9,
      filter: '',
      token: null,
      itemsDetails: [],
      fieldsDetails: [],
      items: [],
      fields: [{
          key: "name",
          label: "Loja",
        },
        {
          key: "sku",
          label: "Barras",
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "totalCost",
          label: "Custo Total",
        },
        {
          key: "unitValue",
          label: "Valor Unitário",
        },
        {
          key: "totalQuantity",
          label: "Qtde Itens",
        },
        {
          key: "totalValue",
          label: "Valor Total",
        },
        {
          key: "profit",
          label: "Lucro",
        },
        {
          key: "markup",
          label: "Markup",
        },
        {
          key: "markdown",
          label: "Markdown",
        },
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Sem Intervalo de Datas",
        },
        {
          value: 1,
          text: "Ontem",
        },
        {
          value: 2,
          text: "Hoje",
        },
        {
          value: 3,
          text: "Últimos 7 dias",
        },
        {
          value: 4,
          text: "Últimos 15 dias",
        },
        {
          value: 5,
          text: "Últimos 30 dias",
        },
        {
          value: 6,
          text: "Com Intervalo de Datas",
        },
      ],
      selectedFilterOption: null,
      filterOptions: [{
          value: null,
          text: "--- Selecione ---",
        },
        {
          value: 1,
          text: "Barras",
        },
        {
          value: 2,
          text: "Descrição",
        }
      ],
      isLoading: false,
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      typeUser: null,
      qtdOrderItems: 0,
      pagePagination: null,

      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      totalRecords: 0,
      totalQuantity: 0,
      totalValue: 0,
      totalCost: 0,
      totalProfit: 0,
    };
  },
  methods: {
    redirect(ref) {
      if (ref == "Home") {
        this.$router.push(`/home`);
      }
    },

    async navigate(page) {
      await this.getOrderItems(page);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value;
            this.getSitesByCompany(this.selectedOptionCompany);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`;

      this.selectedOptionSite = null

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.sites = response.data;

          this.optionsSite = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsSite.length == 1) {
            this.selectedOptionSite = this.optionsSite[0].value;
            await this.getOrderItems(this.currentPage);
          }

          if (this.optionsSite.length > 1 && this.typeUser != 'site') {
            await this.getOrderItems(this.currentPage)
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsSite = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    },

    focus(id) {
      if (id === 'filter') {
        this.filter = ''
      }
      if (document.getElementById(id)) {
        document.getElementById(id).focus();
      }
    },

    clearFilter() {
      this.filter = ''
      this.getOrderItems(this.currentPage)

      if (this.selectedFilterOption != null) {
        this.focus('filter')
      }
    },

    async clearSite() {
      this.selectedOptionSite = null
      await this.getOrderItems(this.currentPage)
    },

    async exportToXLSX(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/order-items/export-to-xlsx`;
      const paramsExport = this.buildParams(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOption, this.startDate, this.endDate, this.selectedFilterOption, this.filter, page, this.perPage, true);

      if (paramsExport != null) {
        try {
          const response = await axios({
            url,
            method: "get",
            params: paramsExport,
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            this.isLoading = false;

            await functions.openFile(response.data.url)
          }
        } catch (error) {
          console.log({
            error
          });

          this.isLoading = false;

          if (!error.response) {
            await this.$alert(
              "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
              "Erro",
              "error"
            );
          }

          if (error.response.status == 403) {
            await this.$alert(
              "Sua sessão expirou. Faça o login novamente!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else if (error.response.status == 404) {
            this.getNotification(
              "Aviso",
              `${error.response.data.message}`,
              "warning"
            );
          } else {
            await this.$alert(
              `Ocorreu um erro ao exportar o relatório! ${error.response.data.message}`,
              "Aviso",
              "warning"
            );
          }
        }
      }
    },

    async getOrderItems(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/order-items/search`;
      const params = this.buildParams(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOption, this.startDate, this.endDate, this.selectedFilterOption, this.filter, page, this.perPage, false);

      if (params != null) {
        try {
          const response = await axios({
            url,
            method: "get",
            params,
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            this.isLoading = false;

            this.pagination = response.data;
            this.pagination.rowsPerPage = this.perPage;
            this.pagination.paginationRange = this.paginationRange;

            this.items = response.data.items;
            this.totalCost = response.data.totalCost
            this.totalQuantity = response.data.totalQuantity
            this.totalValue = response.data.totalValue
            this.totalProfit = response.data.totalProfit

            this.qtdOrderItems = response.data.records;
          }
        } catch (error) {
          console.log({
            error
          });

          this.isLoading = false;

          this.items = [];
          this.pagination = [];
          this.qtdOrderItems = 0;

          this.totalCost = 0;
          this.totalQuantity = 0;
          this.totalValue = 0;
          this.totalProfit = 0;


          if (!error.response) {
            await this.$alert(
              "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
              "Erro",
              "error"
            );
          }

          if (error.response.status == 403) {
            await this.$alert(
              "Sua sessão expirou. Faça o login novamente!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else if (error.response.status == 404) {
            this.getNotification(
              "Aviso",
              `${error.response.data.message}`,
              "warning"
            );
          } else {
            await this.$alert(
              `Ocorreu um erro ao buscar os itens das vendas! ${error.response.data.message}`,
              "Aviso",
              "warning"
            );
          }
        }
      }
    },

    buildParams(companyId, siteId, selectedOption, startDate, endDate, selectedFilterOption, filter, page, pageSize, exportToXLSX) {
      if (this.selectedOptionCompany != null || this.selectedOptionSite != null) {
        let params = new Object();

        if (companyId != null) {
          params.companyId = companyId
        }

        if (siteId != null) {
          params.siteId = siteId
        }

        if (selectedOption == 1) {
          params.startDate = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00")
          params.endDate = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59")
        }

        if (selectedOption == 2) {
          params.startDate = moment().format("YYYY-MM-DD 00:00:00")
          params.endDate = moment().format("YYYY-MM-DD 23:59:59")
        }

        if (selectedOption == 3) {
          params.startDate = moment().subtract(6, 'days').format("YYYY-MM-DD 00:00:00")
          params.endDate = moment().format("YYYY-MM-DD 23:59:59")
        }

        if (selectedOption == 4) {
          params.startDate = moment().subtract(14, 'days').format("YYYY-MM-DD 00:00:00")
          params.endDate = moment().format("YYYY-MM-DD 23:59:59")
        }

        if (selectedOption == 5) {
          params.startDate = moment().subtract(29, 'days').format("YYYY-MM-DD 00:00:00")
          params.endDate = moment().format("YYYY-MM-DD 23:59:59")
        }

        if (selectedOption == 6) {
          if (startDate != null && endDate != null) {
            params.startDate = moment(startDate).format("YYYY-MM-DD 00:00:00")
            params.endDate = moment(endDate).format("YYYY-MM-DD 23:59:59")
          }
        }

        if (selectedFilterOption == 1 && filter != '') {
          params.sku = filter
        }

        if (selectedFilterOption == 2 && filter != '') {
          params.description = filter
        }

        if (exportToXLSX) {
          params.exportToXLSX = exportToXLSX
        } else {
          params.pageSize = pageSize
          params.page = page
        }

        return params;
      } else {
        return null
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.typeUser = localStorage.getItem("typeUser");

    await this.getCompanies();

    this.isLoading = false;
  },
};