var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"animated fadeIn"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Cadastro de Banners do App")])]},proxy:true}])},[_c('div',{attrs:{"id":"web"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Aplicativos")])]},proxy:true}])},[_c('b-row',[_c('b-table',{ref:"selectableTable",staticStyle:{"margin-top":"-15px","margin-bottom":"5px","width":"100%"},attrs:{"id":"tableCompanies","hover":"","striped":"","bordered":"","responsive":"sm","selectable":"","select-mode":_vm.selectMode,"items":_vm.companies,"fields":_vm.fieldsCompanies,"current-page":_vm.currentPage,"per-page":_vm.perPage,"head-variant":"light"},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}}])})],1),_c('b-row',[_c('p',[_c('b-button',{staticClass:"btn",attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v("Limpar seleção")]),_c('b-button',{staticClass:"btn",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.selectAllRows}},[_vm._v("Selecionar todas ")])],1)])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Banner")])]},proxy:true}])},[_c('picture-input',{ref:"pictureInput",staticStyle:{"margin-top":"-15px","margin-bottom":"-5px","width":"50%","height":"50%"},attrs:{"margin":"1","crop":false,"accept":"image/*","autoToggleAspectRatio":true,"removable":true,"size":"30","zIndex":10,"prefill":_vm.url,"custom-strings":{
                    upload: '<h1>Seu dispositivo não aceita este tipo de envio.</h1>',
                    drag: 'Arraste uma imagem <br> (ou clique aqui)',
                    change: 'Mudar foto',
                    remove: 'Remover foto',
                  }},on:{"change":_vm.onChange,"remove":_vm.onRemoved}})],1)],1)],1)],1),_c('div',{attrs:{"id":"mobile"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticStyle:{"max-width":"100%"},attrs:{"id":"cardCompanies","tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Empresas")])]},proxy:true}])},[_c('b-row',[_c('b-table',{ref:"selectableTableMobile",staticStyle:{"margin-top":"-15px","margin-bottom":"5px","width":"100%"},attrs:{"id":"tableCompaniesMobile","hover":"","striped":"","bordered":"","responsive":"sm","selectable":"","select-mode":_vm.selectModeMobile,"items":_vm.companies,"fields":_vm.fieldsCompaniesMobile,"current-page":_vm.currentPage,"per-page":_vm.perPage,"head-variant":"light","small":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
                  var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}}])})],1),_c('b-row',[_c('p',[_c('b-button',{staticClass:"btn",attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v("Limpar seleção")]),_c('b-button',{staticClass:"btn",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.selectAllRows}},[_vm._v("Selecionar todas ")])],1)])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticStyle:{"max-width":"100%","margin-bottom":"20px"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Banner")])]},proxy:true}])},[_c('picture-input',{ref:"pictureInput",staticStyle:{"margin-top":"-15px","margin-bottom":"-5px","width":"100%"},attrs:{"margin":"1","crop":false,"accept":"image/*","autoToggleAspectRatio":true,"removable":true,"size":"40","zIndex":10,"prefill":_vm.url,"custom-strings":{
                    upload: '<h1>Seu dispositivo não aceita este tipo de envio.</h1>',
                    drag: 'Arraste uma imagem <br> (ou clique aqui)',
                    change: 'Mudar foto',
                    remove: 'Remover foto',
                  }},on:{"change":_vm.onChange}})],1)],1)],1)],1)])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"my-2 my-sm-0 mr-sm-2 w100",attrs:{"type":"submit"},on:{"click":function($event){return _vm.redirect('Home')}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"}),_vm._v(" Voltar ")]),_c('b-button',{staticClass:"my-2 my-sm-0 mr-sm-2 w100",attrs:{"variant":"primary","type":"submit","disabled":_vm.selected.length == 0 || !_vm.image || _vm.image==''},on:{"click":_vm.createBanner}},[_c('i',{staticClass:"fa fa-check-circle"}),_vm._v(" Gravar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }