import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";

export default {
  name: "BannersTotemList",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      bannersSiteUser: [],
      fields: [{
          key: "url",
          label: "Banner",
        },
        {
          key: "siteId",
          label: "Loja",
          sortable: true,
        },
        {
          key: "fantasy",
          label: "Nome da Loja",
        },
        {
          key: "action",
          label: "",
        }
      ],
      bannersCompanyUser: [],
      fieldsCompany: [{
          key: "bannerId",
          label: "ID",
        },
        {
          key: "url",
          label: "Banner",
        },
        {
          key: "details",
          label: "",
        },
        {
          key: "action",
          label: "",
        }
      ],
      typeUser: "",
      currentPage: 1,
      perPage: 3,
      perPageDetails: 8,
      selectedOptionCompany: null,
      optionsCompany: [],
      detailsBanner: [],
      bannerImage: "",
      fieldsDetailsBanner: [{
          key: "siteId",
          label: "Loja",
          sortable: true,
        },
        {
          key: "fantasy",
          label: "Nome da Loja",
        },
        {
          key: "action",
          label: "",
        }
      ],
      controlDeleteBannerSite: false,
    }
  },
  methods: {
    redirect(ref) {
      if (ref == 'banners-manager') {
        localStorage.removeItem('redirectBanners');
        localStorage.setItem('redirectBanners', 'banners-list');
      } else {
        localStorage.removeItem('selectedOptionCompanyTotemBanner');
      }
      this.$router.push(ref);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async showDetailsBanner(banner) {
      await this.getDetailsBanner(banner.bannerId, false);

      this.bannerImage = banner.url;

      if (this.detailsBanner.length > 0) {
        await this.$bvModal.show('detailsBanner');
      }
    },

    async deleteBannerByUserCompany(banner) {
      const res = await this.$swal({
        title: "Exclusão",
        text: `Confirma a exclusão do banner ${banner.item.bannerId} da loja ${banner.item.siteId} - ${banner.item.fantasy}?`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#303030",
        cancelButtonColor: "#fe5c3a",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (res.isConfirmed) {
        await this.destroyBannerByUserCompany(banner.item.bannerId, banner.item.id);
      }
    },

    async destroyBannerByUserCompany(bannerId, id) {
      const url = `${config.default.urlBase}/sites/banners/${bannerId}/linked-banner/${id}`;

      try {
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            "Banner excluído!",
            "Sucesso",
            "success"
          );

          await this.$bvModal.hide('detailsBanner');

          this.detailsBanner = [];

          await this.getDetailsBanner(bannerId, true);

          if (this.detailsBanner.length > 0) {
            await this.$bvModal.show('detailsBanner');
          }


          await this.getBanners();
        }

      } catch (error) {
        this.isLoading = false;
        console.log({
          error
        })

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao excluir o banner! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async deleteBanner(banner) {
      const res = await this.$swal({
        title: "Exclusão",
        text: `Confirma a exclusão do banner ${banner.item.bannerId}?`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#303030",
        cancelButtonColor: "#fe5c3a",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (res.isConfirmed) {
        await this.destroyBanner(banner.item.bannerId);
      }
    },

    async destroyBanner(id) {
      const url = `${config.default.urlBase}/sites/banners/${id}`;

      try {
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            "Banner excluído!",
            "Sucesso",
            "success"
          );

          await this.getBanners();
        }

      } catch (error) {
        this.isLoading = false;
        console.log({
          error
        })

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao excluir o banner! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getDetailsBanner(id, beforeDelete) {
      this.isLoading = true
      const url = `${config.default.urlBase}/sites/banners/${id}/details`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.detailsBanner = [];
          this.detailsBanner = response.data;

          this.isLoading = false
        }
      } catch (error) {
        console.error({
          errorMessage: `[GetDetailsBanner] ${error}`,
        });

        this.isLoading = false

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          if (!beforeDelete) {

            this.getNotification(
              "Aviso",
              `${error.response.data.message}`,
              "warning"
            );
          }
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os detalhes do banner! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getBanners() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/banners`;

      localStorage.setItem('selectedOptionCompanyTotemBanner', this.selectedOptionCompany)

      try {
        const response = await axios({
          url,
          method: "GET",
          params: {
            companyId: this.selectedOptionCompany,
          },
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isLoading = false;

          if (this.typeUser == "site") {
            this.bannersSiteUser = response.data;

            if (this.bannersSiteUser.length <= 0) {
              this.getNotification(
                "Aviso",
                `Nenhum banner encontrado com os filtros informados!`,
                "warning"
              );
            }
          } else {
            this.bannersCompanyUser = response.data;

            if (this.bannersCompanyUser.length <= 0) {
              this.getNotification(
                "Aviso",
                `Nenhum banner encontrado com os filtros informados!`,
                "warning"
              );
            }
          }
        } else {
          if (this.typeUser == "site") {
            this.bannersSiteUser = [];
          } else {
            this.bannersCompanyUser = [];
          }
        }

      } catch (error) {
        this.isLoading = false;
        console.log({
          error
        });

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os banners! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value;
            }
          }

          if (this.selectedOptionCompany) {
            await this.getBanners();
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  computed: {
    rows() {
      if (this.typeUser == 'site') {
        return this.bannersSiteUser.length;
      }
      return this.bannersCompanyUser.length;
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.typeUser = localStorage.getItem("typeUser");

    this.selectedOptionCompany = localStorage.getItem('selectedOptionCompanyTotemBanner') ?? null;


    await this.getCompanies();
    this.isLoading = false;
  },
}