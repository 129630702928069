import { render, staticRenderFns } from "./StockBacklogs.html?vue&type=template&id=7576a12e&scoped=true&"
import script from "./StockBacklogs.js?vue&type=script&lang=js&"
export * from "./StockBacklogs.js?vue&type=script&lang=js&"
import style0 from "./StockBacklogs.scss?vue&type=style&index=0&id=7576a12e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7576a12e",
  null
  
)

export default component.exports