import axios from "axios";
import Loading from "vue-loading-overlay";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";
import * as config from "@/config.json";
import moment from "moment";

export default {
  name: "HomeComp",

  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },

  data: () => {
    return {
      isLoading: false,
      loader: 'bars',
      opacity: 0.80,
      token: null,
      dataDashboard: [],
      paramsOrders: [],
      currentPage: 1,
      perPage: 10,
      pagination: {},
      paginationRange: 11,
      selectedPeriod: null,
      totalOrders: [],
      amountOrdersToday: null,
      valueOrdersToday: null,
      averageTicketToday: null,
      amountOrdersSevenDays: null,
      valueOrdersSevenDays: null,
      averageTicketSevenDays: null,
      amountOrdersThirtyDays: null,
      valueOrdersThirtyDays: null,
      averageTicketThirtyDays: null,
      period: [{
          value: 0,
          text: 'Hoje'
        },
        {
          value: 7,
          text: '7 Dias'
        },
        {
          value: 30,
          text: '30 Dias'
        },
      ],
      items: [],
      fields: [{
          key: "position",
          label: "Posição",
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "quantity",
          label: "Quantidade",

        },
        {
          key: "totalValue",
          label: "Valor Total",
        }
      ],
      fieldsMobile: [{
          key: "position",
          label: "Posição",
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "quantity",
          label: "Quantidade",

        },
        {
          key: "totalValue",
          label: "Valor Total",
        }
      ],

      periodSearch: 0,
      chartOptionsOrdersByGroup: {},
      labelsGroup: [],
      seriesGroup: [],

      chartOptions: {
        chart: {
          height: 360,
          width: 700,
          type: "bar",
          background: '#fff',

          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,

            animateGradually: {
              enabled: true,
              delay: 150
            },

            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },

        fill: {
          colors: ['#67A89A'],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
          }
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val > 0 ? val.toFixed(2) : 0
          },
          style: {
            colors: ['#333'],
            fontSize: '12px',
            fontWeight: 'normal',
          },
          offsetY: 5,

          dropShadow: {
            color: '#111',
            blur: 3,
            opacity: 0.8,
          },
        },

        title: {
          text: "Valor de Vendas"
        },

        plotOptions: {
          bar: {
            columnWidth: '50%',
            borderRadius: 5,
            dataLabels: {
              position: 'top',
              orientation: 'vertical',
            }
          },
        },

      },
      series: [{
        name: 'Vendas',
        data: []
      }],

      xaxis: {
        type: "datetime"
      },

      posBySite: [],
      productsSite: [],
      paginationProductsSite: {},
      paginationRangeProductsSite: 11,
      perPageProductsSite: 10,
      currentPageProductsSite: 1,
      companiesToWebhooks: [],
      selectMode: 'multi',
      selectedCompanies: [],
      companiesId: [],
      ordersNotSent: [],
      fieldsOrdersNotSent: [{
          key: "selected",
          label: "",
        },
        {
          key: "isOnline",
          label: "Status"
        },
        {
          key: "companyId",
          label: "ID",
        },
        {
          key: "companyName",
          label: "Nome"
        },
        {
          key: "pendingRecords",
          label: "Pedências"
        }
      ],
      totalOrdersNotSent: 0,
      companiesIdToWebHooks: [],
      messageSendOrders: "",
      posBySiteObject: [{
          varSitefLib: ''
        },
        {
          varSitefParams: ''
        },
        {
          varSitefOtp: ''
        },
        {
          varSitefAllowedTransactions: ''
        },
        {
          varSitefTimerInteraction: ''
        },
      ],
      posId: 0,
      posNumber: 0,
      selectedWeighingMachine: '',
      optionWeighingMachine: [],
      sitefParams: 0,
      sitefLib: 0,
      sitefExternalCom: 0,
      sitefIsDoubleValidation: 0,
      sitefOtp: 0,
      sitefAllowedTransactions: 0,
      timerInteraction: 0,
      sitefDailyToken: '',
      DailyToken: '',
      allowEditSitef: false,
      isCollapseOpen: false,
      typeInput: 'password',
      showPasswordSitef: false,
      selectedSite: 0,
      selectedSitefLib: null,
      optionSitefLib: [{
          value: 0,
          text: 'Selecione...'
        },
        {
          value: 1,
          text: 'clisitef'
        },
        {
          value: 2,
          text: 'plugpag'
        },
        {
          value: 3,
          text: 'stone'
        }
      ],
      checkedCachedCompanies: '',
      offlineWebhooks: [],
      onlineWebhooks: [],
      counter: 0
    };
  },

  methods: {

    selectAllRows() {
      if (this.$refs.selectableCompanies && typeof this.$refs.selectableCompanies.selectAllRows === 'function') {
        this.$refs.selectableCompanies.selectAllRows();
      }
    },
    clearSelected() {
      this.$refs.selectableCompanies.clearSelected();
    },

    async fillModels() {
      this.posBySite.forEach((item) => {
        item.varSitefLib = item.sitefLib
        item.varSitefParams = item.sitefParams
        item.varSitefOtp = item.sitefOtp
        item.varSitefAllowedTransactions = item.sitefAllowedTransactions
        item.varSitefTimerInteraction = item.timerInteraction
      });
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async showModalTotens() {
      if (this.posBySite.length > 0) {

        await this.$bvModal.show('rebootPOS');
      } else {
        this.getNotification(
          "Aviso",
          `Nenhum Totem configurado para o site selecionado!`,
          "warning"
        );
      }
    },

    async buildParamsGetPos(siteId) {
      let params = new Object();

      params.siteId = siteId

      return params
    },

    async getPosBySiteId(site) {
      this.isLoading = true
      this.selectedSite = site
      const url = `${config.default.urlBase}/pos`;
      const params = await this.buildParamsGetPos(this.selectedSite.siteId)

      try {
        const response = await axios({
          url,
          method: 'GET',
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });


        if (response.status == 200) {
          this.isLoading = false

          this.posBySite = response.data;

          await this.fillModels()
          await this.showModalTotens();
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false
        this.posBySite = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os POS! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async buildParamsOrder(companyId, siteId, selectedOption, startDate, endDate) {
      this.paramsOrders = {
        companyId,
        siteId,
        selectedOption,
        startDate,
        endDate
      }
    },

    navigate(page) {
      this.getTopOrderedProducts(page, this.periodSearch);
    },

    async buildDataCommand(deviceId, command) {
      let data = new Object();

      data.deviceId = deviceId,
        data.command = command

      return data
    },

    async commandPOS(pos, command) {
      this.isLoading = true;
      const url = `${config.default.urlBaseCustomer}/sites/pos/command`;
      const data = await this.buildDataCommand(pos.id, command)

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {

          this.isLoading = false;

          await this.getNotification(
            "Aviso",
            "Comando enviado com sucesso!",
            "success"
          );

          await this.$bvModal.hide('rebootPOS');
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        await this.$alert(
          `Não foi possivel executar o comando! ${error.response.data.message}`,
          "Aviso",
          "warning"
        );
      }
    },

    async getOrders(item) {
      const startDate = moment(new Date()).format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');

      await this.buildParamsOrder(item.companyId, item.siteId, 2, startDate, endDate);
      this.$router.push({
        name: "SalesList",
        params: this.paramsOrders
      });
    },

    buildParams(pagina, period) {
      let params = new Object();

      params.pageSize = this.perPage;
      params.page = pagina;
      params.period = period

      return params;
    },

    buildParamsChart(period, pagina) {
      let params = new Object();

      params.period = period;

      params.pageSize = this.perPage;
      params.page = pagina;

      return params;
    },


    async getDataChart(period) {
      this.updateHome(this.currentPage, period);
      this.periodSearch = period
    },

    async updateChart(period) {
      const url = `${config.default.urlBase}/dashboards/landing-page/orders/search`;
      const params = this.buildParamsChart(period, this.currentPage)

      try {
        const response = await axios({
          url,
          method: "GET",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        this.series = [];
        this.seriesGroup = [];

        if (response.status == 200) {
          this.series = [{
            data: response.data.seriesMounted
          }];

          this.seriesGroup = response.data.seriesByGroupMounted.seriesGroup
          this.labelsGroup = response.data.seriesByGroupMounted.labelsGroup

          const newChartOptionsOrdersByGroup = {
            chart: {
              width: 450,
              height: 360,
              type: 'donut',

              dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
              },

              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,

                animateGradually: {
                  enabled: true,
                  delay: 150
                },

                dynamicAnimation: {
                  enabled: true,
                  speed: 350
                }
              }
            },

            stroke: {
              width: 0,
            },

            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 270,
                donut: {
                  size: 60,
                  labels: {
                    show: true,

                    total: {
                      showAlways: true,
                      show: true,
                      formatter: function (w) {
                        return parseFloat((w.globals.seriesTotals.reduce((a, b) => {
                          return a + b
                        }, 0)).toFixed(2))
                      }
                    }
                  }
                }
              }
            },

            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },

              dropShadow: {
                blur: 3,
                opacity: 0.8
              },
            },

            fill: {
              type: 'gradient',
            },

            theme: {
              palette: ['palette6']
            },

            title: {
              text: "Vendas por Grupo"
            },

            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],

            labels: this.labelsGroup,
          }

          this.chartOptionsOrdersByGroup = newChartOptionsOrdersByGroup;
        }

      } catch (error) {
        console.log({
          error
        })

        this.series = [];
        this.seriesGroup = [];

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    async getTopOrderedProducts(page, period) {
      const url = `${config.default.urlBase}/dashboards/landing-page/top-ordered-products/search`;
      const params = this.buildParams(page, period)

      try {
        const response = await axios({
          url,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.items = response.data.items;
          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        this.items = [];
        this.pagination = [];
        this.pagination.rowsPerPage = [];
        this.pagination.paginationRange = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    },

    openCheckHelth(url) {
      window.open(url, '_blank');
    },


    async buildParamsSite(companyId, siteId, fantasy) {
      return {
        companyId,
        siteId,
        fantasy
      }
    },

    async redirectProductsSite(site) {
      const paramsSite = await this.buildParamsSite(site.companyId, site.siteId, site.fantasy)

      this.$router.push({
        name: "ProductsSite",
        params: paramsSite
      });
    },

    async buildParamsMonitoringSite(siteId) {
      return {
        siteId
      }
    },

    async redirectMonitoringSite(site) {
      const paramsSite = await this.buildParamsMonitoringSite(site.siteId)

      this.$router.push({
        name: "Monitoring",
        params: paramsSite
      });
    },


    async openModalPos(posBySite) {

      this.posId = posBySite.id
      this.posNumber = posBySite.number
      this.sitefParams = posBySite.sitefParams
      this.sitefAllowedTransactions = posBySite.sitefAllowedTransactions
      this.sitefOtp = posBySite.sitefOtp

      let selectedItem = this.optionSitefLib.find(item => item.text == posBySite.sitefLib);
      this.selectedSitefLib = selectedItem ? selectedItem.value : 0

      this.sitefExternalCom = posBySite.sitefExternalCom
      this.sitefIsDoubleValidation = posBySite.sitefIsDoubleValidation
      this.timerInteraction = posBySite.timerInteraction

      this.isCollapseOpen = false
      this.sitefDailyToken = ''
      this.allowEditSitef = false


      await this.getWeighingMachine();

      let returnSelectedWeighingMachine
      returnSelectedWeighingMachine = this.optionWeighingMachine.find(option => option.id === posBySite.weighingMachineId)
      this.selectedWeighingMachine = returnSelectedWeighingMachine ? returnSelectedWeighingMachine.value : null

      this.$refs.editPosSite.show();
    },


    async getWeighingMachine() {
      const url = `${config.default.urlBase}/weighing-machine`;

      try {
        const response = await axios({
          url,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          response.data;

          this.optionWeighingMachine = await Promise.all(response.data.map((item, index) => {
            return {
              value: index,
              id: item.id,
              text: `ID: ${item.id} - Tipo: ${item.labelType} - Tamanho Código: ${item.codeSize} - Prefixo: ${item.prefix}`,
            };
          }));
        }

      } catch (error) {
        console.log({
          error
        })
      }
    },

    buildPosData(posId, weighingMachineId, sitefParams, sitefLib, sitefExternalCom, sitefIsDoubleValidation, sitefOtp, sitefAllowedTransactions, timerInteraction) {
      return {
        posId,
        weighingMachineId,
        sitefParams,
        sitefLib: this.optionSitefLib[sitefLib].text,
        sitefExternalCom,
        sitefIsDoubleValidation,
        sitefOtp,
        sitefAllowedTransactions,
        timerInteraction: timerInteraction ? parseInt(timerInteraction) : 0
      }

    },

    cancelPosConfiguration() {
      this.$refs.editPosSite.hide();
    },

    async confirmPosConfiguration() {
      this.isLoading = true

      const posData = this.buildPosData(
        this.posId,
        this.selectedWeighingMachine != null ? this.optionWeighingMachine[this.selectedWeighingMachine].id : 0,
        this.sitefParams,
        this.selectedSitefLib,
        this.sitefExternalCom,
        this.sitefIsDoubleValidation,
        this.sitefOtp,
        this.sitefAllowedTransactions,
        this.timerInteraction
      )

      try {
        const response = await axios({
          url: `${config.default.urlBase}/sites/pos`,
          method: "PATCH",
          data: posData,
          headers: {
            authorization: `Bearer ${this.token}`
          }

        })

        if (response.status == 200) {
          this.isLoading = false

          this.$refs.editPosSite.hide();

          await this.$alert(
            "Configuração do POS alterada!",
            "Sucesso",
            "success"
          );


          await this.getPosBySiteId(this.selectedSite)

        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao alterar as configurações do POS! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }

      }

    },

    clearWeighingMachine() {
      this.selectedWeighingMachine = null
    },

    async alloewdEditionSitef() {

      try {
        if (this.sitefDailyToken != '') {
          const response = await axios({
            url: `${config.default.urlBase}/support/daily/token`,
            method: "GET",
            headers: {
              authorization: `Bearer ${this.token}`
            }
          })

          if (response.status == 200) {

            this.DailyToken = response.data.token

            if (this.DailyToken === this.sitefDailyToken) {
              this.allowEditSitef = true
            } else if (this.sitefDailyToken !== '') {
              this.allowEditSitef = false

              await this.$alert(
                "Token Incorreto!",
                "Aviso",
                "warning"
              );
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.allowEditSitef = false
      }
    },


    async getDashboard() {
      const url = `${config.default.urlBase}/dashboards/landing-page`;

      try {
        const response = await axios({
          url,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.dataDashboard = response.data.sites;

          this.companiesToWebhooks = response.data.companiesToWebhooks;
          this.totalOrdersNotSent = this.companiesToWebhooks.reduce((accumulator, currentValue) => accumulator + currentValue.pendingRecords, 0);

          this.companiesIdToWebHooks = [this.companiesToWebhooks
            .map(item => item.companyId)
            .filter(id => id !== undefined)
            .join(',')
          ];

          this.amountOrdersToday = response.data.totals.amountOrdersToday;
          this.valueOrdersToday = response.data.totals.valueOrdersToday;
          this.averageTicketToday = response.data.totals.averageTicketToday;

          this.amountOrdersSevenDays = response.data.totals.amountOrdersSevenDays;
          this.valueOrdersSevenDays = response.data.totals.valueOrdersSevenDays;
          this.averageTicketSevenDays = response.data.totals.averageTicketSevenDays;

          this.amountOrdersThirtyDays = response.data.totals.amountOrdersThirtyDays;
          this.valueOrdersThirtyDays = response.data.totals.valueOrdersThirtyDays;
          this.averageTicketThirtyDays = response.data.totals.averageTicketThirtyDays;
        }
      } catch (error) {
        console.log({
          error
        })

        this.dataDashboard = [];

        this.totalOrders = [];

        this.amountOrdersToday = [];
        this.valueOrdersToday = [];
        this.averageTicketToday = [];

        this.amountOrdersSevenDays = [];
        this.valueOrdersSevenDays = [];
        this.averageTicketSevenDays = [];

        this.amountOrdersThirtyDays = [];
        this.valueOrdersThirtyDays = [];
        this.averageTicketThirtyDays = [];

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    },

    async updateHome(page, period) {
      this.isLoading = true;

      const totalPeriod = period == 0 ? period + 1 : period;

      await Promise.all([
        this.updateChart(totalPeriod),
        this.getDashboard(),
        this.getTopOrderedProducts(page, period),
      ]);

      this.isLoading = false;
    },

    async buildDataSendOrders(companyIds) {
      let data = new Object();

      data.companyIds = companyIds

      return data
    },


    async showOrdersNotSentErp() {
      this.isLoading = true;

      try {
        const url = `${config.default.urlBase}/stock-management/dead-letter-queue`;
        const response = await axios({
          url,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        console.log({
          response
        })

        if (response.status == 200) {
          this.isLoading = false;
          this.ordersNotSent = response.data

        }
      } catch (error) {
        console.log({
          error
        })
      }


      this.$refs.showOrdersNotSentErp.show();

      this.$nextTick(() => {
        this.selectAllRows();
      });
    },


    onRowSelected(companies) {
      this.selectedCompanies = companies

      if (this.selectedCompanies.length > 0) {
        const arrayCompanies = this.selectedCompanies.map(company => company.companyId);
        this.companiesId = new Array(arrayCompanies.join(","));
      }
    },


    async forceOrdersNotSentErp() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/stock-management/dead-letter-queue/send-to-sqs`;

      const data = await this.buildDataSendOrders(this.companiesId)

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        console.log({
          response
        })

        this.checkedCachedCompanies = response.data.checkedCachedCompanies
        this.offlineWebhooks = response.data.offlineWebhooks
        this.onlineWebhooks = response.data.onlineWebhooks

        if (response.status == 200) {

          this.isLoading = false;
          this.totalOrdersNotSent = 0

          await this.$refs.showOrdersNotSentErp.hide();

          if (response.message != '') {
            this.messageSendOrders = `${response.data.message}`

            // this.$refs.sendOrders.show();

            this.$router.push({
              params: {
                checkedCachedCompanies: this.checkedCachedCompanies,
                offlineWebhooks: this.offlineWebhooks,
                onlineWebhooks: this.onlineWebhooks,
                counter: response.data.counter
              },
              name: "StockBacklogs"
            });
          } else {
            await this.getNotification(
              "Operação concluída",
              "Comando enviado com sucesso!",
              "success"
            )
          }
        }


      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (error.response.status == 420) {
          this.messageSendOrders = `${error.response.data}`

          this.$refs.sendOrders.show();
        } else {
          this.messageSendOrders = `${error.response.data}`

          this.$refs.sendOrders.show();
        }

        await this.updateHome(this.currentPage, 0);
      }
    },

    lockSitefEdition() {
      if (this.allowEditSitef) {
        this.allowEditSitef = false
      }
    },

    showPasswordSitefConfiguration() {
      this.showPasswordSitef = !this.showPasswordSitef

      if (this.showPasswordSitef) {
        this.typeInput = "text"
      } else {
        this.typeInput = "password"
      }

      this.focus("sitefDailyToken")
    },

    focus(id) {
      document.getElementById(id).focus();
    }
  },

  async beforeMount() {
    this.token = localStorage.getItem('token');
    this.series = [];
    this.seriesGroup = [];

    await this.updateHome(this.currentPage, 0);
  },
};